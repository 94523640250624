import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import GlobalStyle from '../../config/globalStyle';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';

const Home = loadable(() => import('../Home'));
const Page404 = loadable(() => import('../Page404'));

const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.materialdesignicons.com/5.0.45/css/materialdesignicons.min.css"
          rel="stylesheet"
        />
      </Helmet>
      <Switch>
        <Route component={Home} exact={true} path="/" />
        <Route component={Page404} path="*" />
      </Switch>
    </Fragment>
  );
};

export default App;
