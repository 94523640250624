import { createGlobalStyle, css } from 'styled-components';

import { color as themeColors } from '../theme';

export const typography = css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
  }

  body {
    color: red;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.7rem;
    font-weight: 300;
  }

  h1 {
    color: ${themeColors.grey03.hex};
    font-size: 5rem;
    font-weight: 700;
    margin: 3rem 0;
  }

  h2 {
    color: ${themeColors.grey03.hex};
    font-size: 4rem;
    font-weight: 700;
    margin: 3rem 0;
  }

  h3 {
    color: ${themeColors.grey03.hex};
    font-size: 3rem;
    font-weight: 700;
    margin: 1.5rem 0;
  }

  h4 {
    color: ${themeColors.grey03.hex};
    font-size: 2.6rem;
    font-weight: 700;
    margin: 1.5rem 0;
  }

  h5 {
    color: ${themeColors.grey03.hex};
    font-size: 2.2rem;
    font-weight: 700;
    margin: 1.5rem 0;
  }

  h6 {
    color: ${themeColors.grey03.hex};
    font-size: 1.8rem;
    font-weight: 700;
    margin: 1.5rem 0;
  }

  p {
    color: ${themeColors.grey03.hex};
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.8rem;
    margin: 1.5rem 0;
  }

  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  a {
    color: ${themeColors.secondary.hex};
    &:hover {
      text-decoration: none;
    }
  }

  blockquote {
    border-left: 0.4rem solid ${themeColors.grey02.hex};
    padding: 0 1.5rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 3rem;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
    li {
      color: ${themeColors.grey03.hex};
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 3rem;
    li {
      color: ${themeColors.grey03.hex};
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  code {
    background-color: ${themeColors.grey02.hex};
    border: 0.1rem solid ${themeColors.tertiary.hex};
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
    margin: 0 0.2rem;
    padding: 0 0.5rem;
    white-space: nowrap;
  }

  table {
    border-collapse: collapse;
    tr {
      &:nth-child(2n) {
        background-color: ${themeColors.grey02.hex};
      }
    }
    th,
    td {
      border: 0.1rem solid ${themeColors.tertiary.hex};
      color: ${themeColors.grey03.hex};
      font-size: 1.8rem;
      line-height: 2.8rem;
      padding: 0.8rem 1.5rem;
    }
    th {
      font-weight: 700;
      text-align: left;
    }
  }

  div {
    color: ${themeColors.grey03.hex};
  }

  img {
    vertical-align: top;
  }
`;

export const Typography = createGlobalStyle`${typography}`;

export default typography;
