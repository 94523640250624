import './config/i18n';

import React, { Suspense } from 'react';

import App from './containers/App';
import { BrowserRouter as Router } from 'react-router-dom';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { useSSR } from 'react-i18next';

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <App />
      </Router>
    </Suspense>
  );
};

loadableReady(() => {
  hydrate(<BaseApp />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
