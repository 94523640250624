export const color = {
  primary: {
    hex: '#6C63FF',
    name: 'Primary',
  },
  secondary: {
    hex: '#FF3B6E',
    name: 'Secondary',
  },
  tertiary: {
    hex: '#D0CDE1',
    name: 'Tertiary',
  },
  grey02: {
    hex: '#F2F2F2',
    name: 'Grey 02',
  },
  grey03: {
    hex: '#3F3D56',
    name: 'Grey 03',
  },
  grey04: {
    hex: '#2A293B',
    name: 'Grey 04',
  },
  white: {
    hex: '#FFFFFF',
    name: 'White',
  },
};
