import i18n from 'i18next';
import i18nLanguageDetector from 'i18next-browser-languagedetector';
import i18nXHRBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const options = {
  debug: false,
  defaultNS: 'translations',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
    formatSeparator: ',',
  },
  load: 'languageOnly',
  ns: ['translations'],
  saveMissing: true,
  wait: process && !process.release,
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(i18nLanguageDetector)
    .use(i18nXHRBackend)
    .use(initReactI18next);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
